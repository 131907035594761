import { type ReactNode, useState, useRef } from 'react';
import { Button, LottiePlayer } from '@repo/design-system-kit';
import { Dots, Carousel, CarouselItem, type CarouselActions } from '@repo/domain-kit/onboarding';
import { IconChevronRightOutlined, IconChevronLeftOutlined } from '@repo/monochrome-icons';
import { useIntl } from 'react-intl';
import cx from 'clsx';
import styles from './marketing-carousel.strict-module.css';

interface CarouselItems {
  title: string;
  description: string;
  relativePath: string;
}

export function MarketingCarousel(): ReactNode {
  const intl = useIntl();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const carouselRef = useRef<CarouselActions>(null);

  const items: CarouselItems[] = [
    {
      title: intl.formatMessage({
        id: 'qonto-invoicing.upsell-page.level1.main-feature1.title',
      }),
      description: intl.formatMessage({
        id: 'qonto-invoicing.upsell-page.level1.main-feature1.subtitle',
      }),
      relativePath: '/freemium-upgrade/upsell-business-account.json',
    },
    {
      title: intl.formatMessage({
        id: 'qonto-invoicing.upsell-page.level1.main-feature2.title',
      }),
      description: intl.formatMessage({
        id: 'qonto-invoicing.upsell-page.level1.main-feature2.subtitle',
      }),
      relativePath: '/freemium-upgrade/upsell-invoice-payment.json',
    },
    {
      title: intl.formatMessage({
        id: 'qonto-invoicing.upsell-page.level1.main-feature3.title',
      }),
      description: intl.formatMessage({
        id: 'qonto-invoicing.upsell-page.level1.main-feature3.subtitle',
      }),
      relativePath: '/freemium-upgrade/upsell-accountant-access.json',
    },
  ];

  function handleClickPrev(): void {
    if (carouselRef.current) {
      carouselRef.current.scrollPrev();
    }
  }

  function handleClickNext(): void {
    if (carouselRef.current) {
      carouselRef.current.scrollNext();
    }
  }

  return (
    <div className={styles.marketingCarousel}>
      <Button
        className={cx(
          styles.carouselButton,
          styles.leftButton,
          selectedIndex > 0 && styles.visible
        )}
        iconOnly
        onPress={handleClickPrev}
        size="large"
        variant="secondary"
      >
        <IconChevronLeftOutlined />
      </Button>
      <Carousel
        onIndexChange={setSelectedIndex}
        options={{ align: 'center', containScroll: false, hideDots: true }}
        ref={carouselRef}
      >
        {items.map((item, index) => (
          <CarouselItem
            className={cx(styles.item, selectedIndex === index && styles.visible)}
            key={item.title}
          >
            <LottiePlayer
              autoplay={selectedIndex === index}
              className={cx(styles.lottie, 'mb-32')}
              locale={intl.locale}
              loop={selectedIndex === index}
              relativePath={item.relativePath}
            />
            <h2 className="title-2 mb-16">{item.title}</h2>
            <p className="body-2">{item.description}</p>
          </CarouselItem>
        ))}
      </Carousel>
      <Button
        className={cx(
          styles.carouselButton,
          styles.rightButton,
          selectedIndex < items.length - 1 && styles.visible
        )}
        iconOnly
        onPress={handleClickNext}
        size="large"
        variant="secondary"
      >
        <IconChevronRightOutlined />
      </Button>
      <Dots count={items.length} index={selectedIndex} />
    </div>
  );
}

import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmLayout } from 'qonto/react/pricing/components/confirm-layout';
import { ConfirmBillingSummary } from 'qonto/react/pricing/components/confirm-billing-summary';
import { ConfirmHeader, type ProductInfo } from 'qonto/react/pricing/components/confirm-header';
import type { DataContext } from '../data-context.type';
import { ConfirmDisclaimers } from '../confirm-disclaimers';

interface ConfirmUpgradeProps {
  header: ReactElement;
  context: DataContext;
  // TODO move addon and pricePlan logic here
  addon?: ProductInfo;
  pricePlan?: ProductInfo;
  isAddonOnFreeTrial?: boolean;
  onConfirm: () => void;
  isLoading?: boolean;
}

interface Products {
  title: string;
  price: string;
}

export function ConfirmUpgrade({
  header,
  context,
  addon,
  pricePlan,
  isAddonOnFreeTrial,
  onConfirm,
  isLoading,
}: ConfirmUpgradeProps): ReactElement {
  const { formatDate, formatMessage, formatNumber } = useIntl();

  const [products, setProducts] = useState<Products[]>([]);
  const {
    total_estimate: totalEstimate,
    hasInsufficientFunds,
    isAddonBlocked,
    targetAddon,
    targetPricePlan,
    errors,
    warnings,
  } = context;

  const isCtaDisabled = hasInsufficientFunds || isAddonBlocked || isLoading;
  useEffect(() => {
    const productsArr: Products[] = [];
    if (targetPricePlan && pricePlan?.title) {
      productsArr.push({
        title: pricePlan.title,
        price: formatNumber(Number(targetPricePlan.prorated_amount.value), {
          style: 'currency',
          currency: targetPricePlan.prorated_amount.currency,
        }),
      });
    }
    if (targetAddon && addon?.title) {
      productsArr.push({
        title: addon.title,
        price: formatNumber(Number(targetAddon.prorated_amount.value), {
          style: 'currency',
          currency: targetAddon.prorated_amount.currency,
        }),
      });
    }

    setProducts(productsArr);
  }, [addon?.title, formatNumber, pricePlan?.title, targetAddon, targetPricePlan]);

  return (
    <ConfirmLayout header={header} testId="data-test-addon-change-confirm">
      <>
        <ConfirmHeader
          addon={
            targetAddon && addon
              ? {
                  title: addon.title,
                  price: addon.price,
                  subtitle: addon.subtitle,
                  priceNote: formatMessage({
                    id: 'subscription.change.confirmation.addon.billing.price-note',
                  }),
                }
              : undefined
          }
          pricePlan={
            targetPricePlan && pricePlan
              ? {
                  title: pricePlan.title,
                  price: pricePlan.price,
                  subtitle: pricePlan.subtitle,
                  priceNote: formatMessage({
                    id: 'subscription.change.confirmation.addon.billing.price-note',
                  }),
                }
              : undefined
          }
        />
        <ConfirmBillingSummary
          billing={formatMessage(
            {
              id: 'subscription.change.confirmation.summary.upsizing.subtitle',
            },
            {
              date: formatDate(totalEstimate.next_billing_date, {
                format: 'long',
              }),
            }
          )}
          confirmButtonTestId="data-test-addon-change-confirm-next-step"
          confirmText={
            isAddonOnFreeTrial
              ? formatMessage({ id: 'subscription.change.addons.cta.free-trial' })
              : formatMessage({ id: 'btn.confirm' })
          }
          isCtaDisabled={isCtaDisabled}
          isLoading={isLoading}
          onConfirm={onConfirm}
          price={formatNumber(Number(totalEstimate.vat_included_amount.value), {
            style: 'currency',
            currency: totalEstimate.vat_included_amount.currency,
          })}
          products={products}
          testId="data-test-addon-total"
          total={formatMessage({ id: 'subscription.change.confirmation.charge' })}
          totalEstimate={totalEstimate}
        >
          <ConfirmDisclaimers
            addonName={targetAddon?.productName}
            errors={errors}
            hasInsufficientFunds={hasInsufficientFunds}
            planName={targetPricePlan?.productName}
            warnings={warnings}
          />
        </ConfirmBillingSummary>
      </>
    </ConfirmLayout>
  );
}

import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmLayout } from 'qonto/react/pricing/components/confirm-layout';
import { ConfirmBillingSummary } from 'qonto/react/pricing/components/confirm-billing-summary';
import { ConfirmHeader } from 'qonto/react/pricing/components/confirm-header';
import type { SubscriptionChangeDataContext, TargetSubscription } from '../../data-context.type';

interface ConfirmUpgradeProps {
  targetSubscription?: TargetSubscription;
  context: SubscriptionChangeDataContext;
  hasInitialTrial?: boolean;
  planName?: string;
  isLoading?: boolean;
  onConfirm: () => void;
  isBlocked: boolean;
}

export function ConfirmUpgrade({
  context,
  hasInitialTrial,
  targetSubscription,
  planName,
  isLoading,
  onConfirm,
  isBlocked,
}: ConfirmUpgradeProps): ReactElement {
  const { formatMessage, formatNumber } = useIntl();
  const isDownsizing = context.recurrence === 'monthly';
  const initialTrialSubtitle =
    hasInitialTrial && 'subscription.change.confirmation.subscription.initial-trial.subtitle';

  const { estimatedPrice, hasInsufficientFunds } = context;
  const isCtaDisabled = hasInsufficientFunds || isBlocked;
  return (
    <ConfirmLayout
      header={formatMessage({ id: 'subscription.change.confirmation.new.title' })}
      testId="data-test-subscription-change-confirm"
    >
      <>
        <ConfirmHeader
          pricePlan={
            targetSubscription
              ? {
                  title: formatMessage(
                    {
                      id: isDownsizing
                        ? 'subscription.change.confirmation.subscription.downsizing.title'
                        : 'subscription.change.confirmation.subscription.upsizing.title',
                    },
                    {
                      planName,
                    }
                  ),
                  price: formatNumber(Number(targetSubscription.prorated_amount.value), {
                    style: 'currency',
                    currency: targetSubscription.prorated_amount.currency,
                  }),
                  subtitle: formatMessage(
                    {
                      id:
                        initialTrialSubtitle ||
                        (isDownsizing
                          ? 'subscription.change.confirmation.subscription.downsizing.subtitle'
                          : 'subscription.change.confirmation.subscription.upsizing.subtitle'),
                    },
                    {
                      amount: formatNumber(Number(targetSubscription.product_price.value), {
                        style: 'currency',
                        currency: targetSubscription.product_price.currency,
                      }),
                    }
                  ),
                }
              : undefined
          }
        />
        <ConfirmBillingSummary
          // TODO update billing
          billing={formatMessage({
            id: 'subscription.change.confirmation.summary.upsizing.subtitle',
          })}
          confirmButtonTestId="data-test-confirm-cta"
          confirmText={formatMessage({ id: 'subscription.change.confirmation.cta' })}
          isCtaDisabled={isCtaDisabled}
          isLoading={isLoading}
          onConfirm={onConfirm}
          price={formatNumber(Number(estimatedPrice.vat_included_amount.value), {
            style: 'currency',
            currency: estimatedPrice.vat_included_amount.currency,
          })}
          // TODO update products
          products={[]}
          testId="data-test-subscription-change-confirm-total"
          total={
            estimatedPrice.type === 'charge'
              ? formatMessage({ id: 'subscription.change.confirmation.charge' })
              : formatMessage({ id: 'subscription.change.confirmation.refund' })
          }
          totalEstimate={estimatedPrice}
        >
          {/* eslint-disable-next-line react/jsx-no-useless-fragment, formatjs/no-literal-string-in-jsx -- TODO: add disclaimers */}
          <>disclaimers</>
        </ConfirmBillingSummary>
      </>
    </ConfirmLayout>
  );
}

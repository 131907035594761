import type { ReactElement } from 'react';
import { ConfirmLayout } from 'qonto/react/pricing/components/confirm-layout';
import { ConfirmHeader } from 'qonto/react/pricing/components/confirm-header';

interface ConfirmUpgradeProps {
  header: ReactElement;
  subtitle: string;
  title: string;
}

export function ConfirmUpgrade({ header, subtitle, title }: ConfirmUpgradeProps): ReactElement {
  return (
    <ConfirmLayout header={header} testId="data-test-free-confirm-trial-revamp">
      <ConfirmHeader pricePlan={{ price: '0', subtitle, title }} />
    </ConfirmLayout>
  );
}

import React, { useState } from 'react';
import type { ReactNode } from 'react';
import {
  Button,
  Checkbox,
  Flag,
  Select,
  SelectOption,
  type CountryCode,
} from '@repo/design-system-kit';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEmberService } from '@qonto/react-migration-toolkit/react/hooks';
import styles from './certificate-download.strict-module.css';

interface LanguageOption {
  code: string;
  name: string;
  flag: string;
}

const COUNTRY_LANGUAGE_OPTIONS: Record<string, LanguageOption> = {
  FR: { code: 'fr', name: 'Français', flag: 'FR' },
  DE: { code: 'de', name: 'Deutsch', flag: 'DE' },
  IT: { code: 'it', name: 'Italiano', flag: 'IT' },
  ES: { code: 'es', name: 'Español', flag: 'ES' },
  PT: { code: 'pt', name: 'Português', flag: 'PT' },
  NL: { code: 'nl', name: 'Nederlands', flag: 'NL' },
  AT: { code: 'de', name: 'Deutsch', flag: 'DE' },
  BE: { code: 'fr', name: 'Français', flag: 'FR' },
};

const ENGLISH_OPTION: LanguageOption = { code: 'en', name: 'English', flag: 'GB' };

function getLanguageOptionForCountry(countryCode: string): LanguageOption {
  return COUNTRY_LANGUAGE_OPTIONS[countryCode] || ENGLISH_OPTION;
}

export function CertificateDownload(): ReactNode {
  const { formatMessage } = useIntl();
  const organizationManager = useEmberService('organization-manager');

  const [selectedLocale, setSelectedLocale] = useState<string | null>(null);
  const [withBalance, setWithBalance] = useState(false);

  const organization = organizationManager.organization;
  const orgLegalCountry = organization.legalCountry;

  const countryOption = getLanguageOptionForCountry(orgLegalCountry);

  // Mock loading state for UI (will be implemented in PR 3)
  const isBusy = false;

  // Placeholder submit handler (will be implemented in PR 3)
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    // Implementation will be added in PR 3
  };

  return (
    <div
      aria-busy={isBusy}
      className={styles.container}
      data-test-certificate-download
      data-test-custom-section-checkbox-container
    >
      <div className={styles.content}>
        <h4 className="title-4">
          <FormattedMessage id="bank_accounts.certificate.title" />
        </h4>
        <p>
          <FormattedMessage id="bank_accounts.certificate.subtitle" />
        </p>

        <form className={styles.form} onSubmit={handleSubmit}>
          <Select
            aria-label=""
            className={styles.select}
            isDisabled={isBusy}
            label={formatMessage({ id: 'bank_accounts.download.selector-title' })}
            onSelectionChange={key => {
              setSelectedLocale(key as string);
            }}
            placeholder={formatMessage({ id: 'bank_accounts.download.selector-placeholder' })}
            selectedKey={selectedLocale}
          >
            <SelectOption id={countryOption.code}>
              <div className={styles.optionContent}>
                <Flag
                  className={styles.flagIcon}
                  code={countryOption.flag as CountryCode}
                  size="small"
                  variant="square"
                />
                <span className={styles.optionText}>{countryOption.name}</span>
              </div>
            </SelectOption>

            <SelectOption id={ENGLISH_OPTION.code}>
              <div className={styles.optionContent}>
                <Flag
                  className={styles.flagIcon}
                  code={ENGLISH_OPTION.flag as CountryCode}
                  size="small"
                  variant="square"
                />
                <span className={styles.optionText}>{ENGLISH_OPTION.name}</span>
              </div>
            </SelectOption>
          </Select>

          <Checkbox
            aria-label=""
            className={styles.checkbox}
            isDisabled={isBusy}
            isSelected={withBalance}
            onChange={setWithBalance}
          >
            <FormattedMessage id="bank_accounts.certificate.checkbox" />
          </Checkbox>

          <Button
            className={styles.button}
            data-test-certificate-download-button
            isDisabled={isBusy}
            size="medium"
            type="submit"
            variant="secondary"
          >
            <FormattedMessage id="bank_accounts.share.download.cta" />
          </Button>
        </form>
      </div>
    </div>
  );
}

import { SkeletonLoader } from '@repo/design-system-kit';
import { memo, type HTMLAttributes, type ReactNode } from 'react';
import styles from './styles.strict-module.css';

interface TableCellProps extends HTMLAttributes<HTMLDivElement> {
  isLoading: boolean;
  isLabel?: boolean;
  align?: 'left' | 'right' | 'center';
  amount?: string | number;
  showProjectedForecast?: boolean;
  displayForecastPreviewForCategory?: string;
  confirmFlashForecastForCategory?: string;
}
export function TableCell({
  isLoading,
  isLabel = false,
  align = 'center',
  children,
  ...props
}: TableCellProps): ReactNode {
  return (
    <div className={styles.tableCell} style={{ justifyContent: align }} {...props}>
      {isLoading ? (
        <div
          className={styles.skeletonContainer}
          data-testid="table-cell-loading"
          style={{ justifyContent: align }}
        >
          {isLabel ? (
            <SkeletonLoader.Line borderRadius="50%" height="8px" standalone width="8px" />
          ) : null}
          <SkeletonLoader.Line height="12px" standalone width="60%" />
        </div>
      ) : (
        children
      )}
    </div>
  );
}

export const MemoizedTableCell: React.MemoExoticComponent<React.FC<TableCellProps>> = memo(
  TableCell,
  (previousProps, nextProps) => {
    return (
      previousProps.isLoading === nextProps.isLoading &&
      previousProps.isLabel === nextProps.isLabel &&
      previousProps.align === nextProps.align &&
      previousProps.amount === nextProps.amount &&
      previousProps.showProjectedForecast === nextProps.showProjectedForecast &&
      previousProps.displayForecastPreviewForCategory ===
        nextProps.displayForecastPreviewForCategory &&
      previousProps.confirmFlashForecastForCategory === nextProps.confirmFlashForecastForCategory
    );
  }
);
